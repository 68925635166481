<template>
	<b-modal :id="$route.name + 'CreateModal'" title="Добавить класс автомобиля" size="sm" hide-footer no-enforce-focus :no-close-on-backdrop="true">
		<b-row class="mb-1">
			<b-col cols="12">
				<label for="">Класс</label>
				<b-input v-model="classCar.name" placeholder="Название класса" />
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="12" class="mt-1">
				<label for="">Категория</label>
				<b-form-select  v-model="classCar.category_car_id">
				<b-form-select-option disabled :value="null">Выберите категорию</b-form-select-option>
				<b-form-select-option
					v-for="category in categories" :key="category.id"
					:value="category.id">{{ category.name }}</b-form-select-option>
				</b-form-select>
			</b-col>
		</b-row>
		<b-row class="mb-1 mt-2">
			<b-col class="d-flex justify-content-end">
				<b-button :disabled="submitButtonDisabled || !classCar.name" @click="createСlassCar()" variant="primary">Добавить</b-button>
				<b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
			</b-col>
		</b-row>
	</b-modal>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	component: {
		ToastificationContent,
	},
	props: ['categories'],
	data(){
		 return {
			classCar: {
					name: null,
					category_car_id: null,
					is_active: true
			  },
			  submitButtonDisabled: false,
		 }
	},
	methods: {
		closeModal() {
			this.classCar.name = null
			this.submitButtonDisabled = false
			this.$bvModal.hide(this.$route.name + 'CreateModal')
		 },
		 createСlassCar() {
			  this.submitButtonDisabled = true
			  this.$http
			  .post('car-settings/classes', this.classCar)
			  .then(() => {
					this.closeModal()
					this.$emit('refresh')
					this.$toast({
						 component: ToastificationContent,
						 position: 'top-right',
						 props: {
							  title: 'Класс автомобиля создан!',
							  icon: 'CheckIcon',
							  variant: 'success',
						 },
					})
			  })
			  .catch(err => {
					this.$toast({
						 component: ToastificationContent,
						 position: 'top-right',
						 props: {
							  title: 'Некорректные данные!',
							  icon: 'XIcon',
							  variant: 'danger',
							  text: err.response.data.errors,
						 },
					})
			  })
			  .finally(() => {
					this.submitButtonDisabled = false
			  })
		 },	 
	}
}
</script>