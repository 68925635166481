<template>
	<div>
		 <b-modal 
		 id="editClassCar" 
		 title="Редактирование класса автомобиля" 
		 size="sm" 
		@shown="getClassCar"
		 hide-footer 
		 no-enforce-focus 
		 :no-close-on-backdrop="true">
			  <b-row>
					<b-col cols="12">
						<label for="">Класс</label>
						 <b-input v-model="classCar.name" placeholder="Класс автомобиля" />
					</b-col>
			  </b-row>
			  <b-row class="mb-1">
					<b-col cols="12" class="mt-1">
						<label for="">Категория</label>
						 <b-form-select :disabled="categoriesSelectDisabled" v-model="classCar.category_car_id" id="category">
							  <b-form-select-option disabled :value="null">Выберите категорию</b-form-select-option>
							  <b-form-select-option
							  v-for="category in categories" :key="'category-' + category.id"
							  :value="category.id">{{ category.name }}</b-form-select-option>
						 </b-form-select>
					</b-col>
			  </b-row>
			  <b-col cols="12">
			<b-form-checkbox v-model="classCar.is_active" checked="true" name="check-button" switch inline>
				{{ this.classCar.is_active ? 'Активный' : 'Не активный' }}
				</b-form-checkbox>
		</b-col>
		<b-row class="mb-1 mt-2">
			<b-col class="d-flex justify-content-end">
				<b-button :disabled="submitButtonDisabled || !classCar.name" @click="update" variant="primary">
				Изменить
				</b-button>
				<b-button @click="closeModal" class="ml-1" variant="danger">Отмена</b-button>
			</b-col>
		</b-row>
		 </b-modal>
	</div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	component: {
		 ToastificationContent,
	},
	props:{
		categories: {
			type:Array,
			default: [],
		},
		id: {
			type:Number,
			default: null,
		}
	},
	data(){
		 return {
			classCar: {
					name: null,
					category_car_id: null,	
					class_car_id: null,
					is_active: null,
			  },
			  submitButtonDisabled: false,
			  categoriesSelectDisabled: false,
		 }
	},
	methods: {
		 closeModal() {
			this.classCar.name = null;
			this.submitButtonDisabled = false;
			this.$bvModal.hide('editClassCar');
		 },
		 getClassCar() {
			this.$http
				.get(`car-settings/classes/${this.id}/edit`)
				.then((res) => {
				this.classCar = res.data;
				this.classCar.is_active = res.data.is_active === 1 ? true : false;
				})
				.catch((err) => {
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Ошибка!',
						icon: 'XIcon',
						variant: 'danger',
						text: 'Не удалось загрузить дополнительную опцию',
					},
				});
				});
		},
		update() {
			this.submitButtonDisabled = true;
			this.classCar.is_active = this.classCar.is_active ? 1 : 0;
			this.$http
				.patch(`car-settings/classes/${this.id}`, this.classCar)
				.then((res) => {
				this.closeModal();
				this.$emit('refresh');
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Доп. опции водителя изменены!',
						icon: 'CheckIcon',
						variant: 'success',
						text: res.data.message,
					},
				});
				})
				.catch((err) => {
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Ошибка!',
						icon: 'XIcon',
						variant: 'danger',
						text: 'Некорректные данные',
					},
				});
				})
				.finally(() => {
				this.submitButtonDisabled = false;
				});
		},
		},
	};
	</script>