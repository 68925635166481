<template>
	<div>
		<div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
			<b-spinner variant="primary" label="Text Centered" />
		</div>
		<div v-else>
			<table-car-classes :classCars="classCars" @editForm="editForm"/>
		</div>
		<modal-classes-car :categories="categories" @refresh="refresh" />
		<modal-classes-car-edit :categories="categories" :id="id" @refresh="refresh" />
	</div>
 </template>
 <script>
 import ModalClassesCar from '@/views/component/Modal/ModalClassesCar/ModalClassesCar.vue'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import ModalClassesCarEdit from '@/views/component/Modal/ModalClassesCar/ModalClassesCarEdit.vue'
 import tableCarClasses from '@/views/component/Table/tableCarClasses.vue'
 export default {
	components: {
		ModalClassesCar,
		ToastificationContent,
		ModalClassesCarEdit,
	  	tableCarClasses
	},
	data() {
	  return {
		 showPreloader: false,
		 classCars: [],
		 categories: [],
		 id: undefined,
	  }
	},
	mounted(){
		this.refresh()
	  	this.openFilter()
	  	this.getCategories()
	},
	methods: {
		refresh(){
		 this.showPreloader = true
		 this.$http
		 .get('car-settings/classes')
		 .then(res => {
			this.classCars = res.data
			this.showPreloader = false
		 })
		 .catch(err => {
			this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ошибка!',
            icon: 'XIcon',
            variant: 'danger',
            text: err.response.data.errors,
          },
        })
		 })
	  },
	  editForm(id) {
			this.id = id
			this.$bvModal.show('editClassCar')
	  },
	getCategories() {
		 this.$http.get('/car-settings/categories', {params: {is_active: true}})
		 .then((res) => {
			this.categories = res.data
		 })
	  },
	  openFilter(){
		 let allRoutes = this.$store.state.draggableTab.tabs
		 let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
		 let arrayRoutes = []
		 allRoutes.forEach(element => {
			arrayRoutes.push(element.path)
		 });
		 if (arrayRoutes.includes(this.$route.path) == true) {
			this.showPreloader = true
			this.$http
			.get(`${this.$route.name}`, {params: param}).then(res => {
			  this.classCars = res.data
			  this.showPreloader = false
			  this.$store.commit('REFRESH_DATA', false)
			})
		 }else if(arrayRoutes.includes(this.$route.path) == false){
			this.$bvModal.show(this.$route.name + 'filter')
		 }
	  },
	},
	computed: {
	  fetchingNewData(){
		 return this.$store.state.refresh.fetchingNewData
	  }
	},
	watch: {
	  fetchingNewData(val){
		  let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
		 if (val){
			this.$http
			.get(`${this.$route.name}`, {params: params})
			.then(res =>{
			  this.classCars = res.data
			  this.$store.commit('pageData/setdataCount', this.classCars.length)
			  this.$store.commit('REFRESH_DATA', false)
			})
		 }
	  }
	}
 }
 </script>