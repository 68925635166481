<template>
	<div>
	  <b-row>
		 <b-col cols="12">
			<b-card class="my-card m-0">
			  <b-table
				 striped
				 small
				 id="myTables"
				 bordered
				 selectable
				 selected-variant="primary"
				 select-mode="single"
				 responsive="sm"
				 head-row-variant="secondary"
				 :items="classCars"
				 :fields="fields"
				 @row-dblclicked="edit"
				 @row-clicked="clicked"
				 >
				 <template #cell(checkbox)="row">
					<b-form-checkbox
					v-model="row.detailsShowing"
					plain
					class="vs-checkbox-con"
					@change="row.toggleDetails"
					>
					</b-form-checkbox>
				 </template>  
				 <template #cell(is_active)="data">
              <div v-if="data.item.is_active === 1">
                Активен
              </div>
              <div v-else>
                Не активен
              </div>
            </template>   
			  </b-table>
			</b-card>
		 </b-col>
	  </b-row>
	</div>
	</template>
	<script>
	import { resizeable } from "@/utils/TableFunctions/resizeable.js";
	import { cellSelect } from "@/utils/TableFunctions/cellSelect";
	export default {
	  props: ['classCars'],
	  data() {
		 return {
			fields: [
			  { key: 'checkbox', label: 'Флаг',},
			  { key: 'id', label: 'ID', sortable: true},
			  { key: 'name', label: 'Классы', sortable: true},
			  { key: 'category', label: 'Категория', sortable: true},
			  { key: 'is_active', label: 'Статус', sortable: true},
			  { key: 'created_at', label: 'Создан', sortable: true},
			]
		 }
	  },
	  mounted() {
		 resizeable()
	  },
	  methods: {
		 clicked(item, index, event){
			this.$store.state.filterPlus.tableIndex = index
			const clickedElement = event.target;
					const clickedCell = clickedElement.closest('td');
	
					if (!clickedCell) {
						 return;
					}
	
					const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);
	
					if (columnIndex < 0 || columnIndex >= this.fields.length) {
						 return;
					}
	
					const clickedField = this.fields[columnIndex];
					this.$store.state.filterPlus.keyFilter = clickedField.key;
	
					this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
					cellSelect()
			  },
			  edit(item) {
			this.$emit('editForm', item.id)
		 },
	  }
	}
	</script>
	<style scoped lang="scss">
	@import '@core/scss/vue/libs/vue-context.scss';
	.my-card .card-body{
		 padding: 0px;
	}
	.my-card{
	overflow: auto; 
	height: calc(100vh - 92px);
	}
	@media (max-height: 700px) {
	  .my-card{
		 overflow: auto; 
		 height: 530px;
		 }
	}
	table{
		 outline: none; 
		 table-layout: fixed; 
		 -webkit-user-select: none;
		 overflow: hidden;
		 position: relative;
	}
	tr, td, th{
		 padding: 0;
	}
	td, th{ 
		 width: 100px; 
		 white-space: nowrap; 
		 overflow: hidden;  
		 text-align: left; 
		 box-sizing: border-box; 
		 min-width: 60px;
		 }    
	</style>